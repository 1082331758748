<template>
  <div class="center">
    <div class="content">
      <div class="login-view">
        <div class="removerr">Removerr</div>
        <form @submit.prevent="submitForm">
          <div class="form-cont">
            <input type="text" id="username" autocomplete="username" placeholder="Username" v-model="form.username">
          </div>
          <div class="form-cont">
            <input type="password" autocomplete="current-password" id="password" placeholder="Password" v-model="form.password">
          </div>
          <button type="Login" @click="login">Login</button>
          <div class="status-message" v-if="status" :class="statusClass">{{ statusMessage }}</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '',
        email: ''
      },
      status: false,
      statusMessage: '',
      statusClass: "error"
    };
  },
  created() {
    if (this.$cookies.get("session")) {
      this.$router.push({ name: 'Dashboard' });
    }
  },
  methods: {
    login() {
      axios.post(`/api/login`, {
        username: this.form.username,
        password: this.form.password
      },
      {
        withCredentials: true
      })
      .then((status) => {
        console.log(status);
        this.status = true;
        this.statusMessage = status.data.message;
        this.statusClass = "success";
        this.$router.push({ name: 'Dashboard' });
      })
      .catch((error) => {
        this.status = true;
        this.statusMessage = error.response.data.message;
        this.statusClass = "error";
      });
    }
  }
};
</script>

<style scoped>
.login-view {
  padding: 22px;
  border-radius: 4px;
  background-color: var(--window-background);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
}

.content {
  flex: 0 0 200px;
}

.form-cont {
  margin: 20px 0 20px 0;
  width: 100%;
}

.outer-view {
  background: repeating-linear-gradient(
    135deg,
    var(--deco-dark-grey) 0px,
    var(--deco-dark-grey) 11px,
    var(--deco-grey) 11px,
    var(--deco-grey) 22px
  );
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 100px 5px #000;
}

.inner-view {
  background: var(--background-color);
  padding: 100px;
}

label {
  display: block;
  margin: 0px 0 5px;
  font-size: var(--normal-text-size);
  font-weight: 600;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 7px 0 7px 7px;
  margin-bottom: 10px;
  background: var(--text-box-color);
  border: none;
  border-bottom: 0.18em solid var(--text-box-border-color);
  outline: 0;
  color: inherit;
  font-size: var(--normal-text-size);
  box-shadow: var(--normal-shadow);
}

input:focus, input:active {
  border: none;
  border-bottom: 0.18em solid var(--element-focus);
}

button {
  width: 100%;
  padding: 9px 18px;
  margin-right: 5px;
  background-color: var(--element-no-focus);
  color: var(--font-color);
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
  font-size: var(--normal-text-size);
  box-shadow: var(--normal-shadow);
}

button:hover {
  background-color: var(--element-focus);
  color: var(--font-color-focus);
}

.error {
  color: var(--error-message);
  margin-bottom: 10px;
}

.success {
  color: var(--success-message);
  margin-bottom: 10px;
}

.status-message {
  width: 100%;
  padding: 9px 0 0 0;
}

</style>