import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from '@/views/LoginForm.vue';
import DashboardView from '@/views/DashboardView.vue';
import MediaView from '@/views/MediaView.vue';

const routes = [
  { path: '/', name: 'LoginForm', component: LoginForm },
  { path: '/dashboard', name: 'Dashboard', component: DashboardView },
  { path: '/media/:id', name: 'Media', component: MediaView }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
