<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/base.css';

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Open Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}

body {
  background-color: var(--background-color);
  justify-content: center;
  align-items: center;
  /* margin: 0px; */
}
</style>
