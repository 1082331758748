import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueCookies from 'vue-cookies';
import VueLazyLoad from 'vue3-lazyload'

createApp(App).use(router).use(VueCookies).use(VueLazyLoad, {
  loading: '',
  error: '',
}).mount('#app')
